<template>
  <div>
    <PageHeader fileName="header-7" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('section1.heading')" />

            <ul class="mt-auto mb-4 h4">
              <li class="mb-3">
                <i class="fa fa-arrow-right text-tertiary mr-3"></i>
                <a class="text-dark a-decorated" href="#barcelona">
                  {{ $lang("section1.barcelona") }}
                </a>
              </li>
              <li>
                <i class="fa fa-arrow-right text-tertiary mr-3"></i>
                <a class="text-dark a-decorated" href="#madrid">
                  {{ $lang("section1.madrid") }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-6">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("section1.paragraph1") }}
            </h6>
            <p>
              {{ $lang("section1.paragraph2") }}
            </p>
            <p>
              {{ $lang("section1.paragraph3") }}
            </p>
            <p>
              {{ $lang("section1.paragraph4") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <SectionHeader fileName="campus-brc" id="barcelona">
      <h1 v-html="$lang('barcelona.title')" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-5">
            <h3 class="text-tertiary mb-4">{{ $lang("barcelona.header") }}</h3>
            <h6 class="fw-300 lh-3 mb-5">
              {{ $lang("barcelona.subheader") }}
            </h6>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p>
              {{ $lang("barcelona.paragraph1") }}
            </p>

            <p>
              {{ $lang("barcelona.paragraph2") }}
            </p>

            <p class="mb-0">
              {{ $lang("barcelona.paragraph3") }}
            </p>
          </div>
        </div>

        <div class="row mt-8">
          <div class="col-lg-12">
            <Accreditations theme="tertiary" headingLevel="h4" />
          </div>
        </div>

        <div class="row mt-8">
          <div class="col-lg-3">
            <h3 class="mb-4 text-tertiary">
              {{ $lang("barcelona.information.title") }}
            </h3>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <div class="card card-outline-tertiary d-flex flex-column h-100">
              <div class="card-header">
                <h6>{{ $lang("barcelona.information.card1.title") }}</h6>
              </div>
              <div class="card-body mt-auto">
                <p class="mb-0">
                  {{ $lang("barcelona.information.card1.body") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <div class="card card-outline-tertiary d-flex flex-column h-100">
              <div class="card-header">
                <h6>{{ $lang("barcelona.information.card2.title") }}</h6>
              </div>
              <div class="card-body mt-auto">
                <ul
                  class="disc-style d-flex flex-column gap-3"
                  v-html="$lang('barcelona.information.card2.body')"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <div class="card card-outline-tertiary d-flex flex-column h-100">
              <div class="card-header">
                <h6>{{ $lang("barcelona.information.card3.title") }}</h6>
              </div>
              <div class="card-body mt-auto">
                <p class="mb-0">
                  {{ $lang("barcelona.information.card3.body") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-9 pb-5">
        <swiper
          :modules="modules"
          :pagination="{ clickable: false }"
          :breakpoints="{
            '0': {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            '768': {
              slidesPerView: 2.5,
              spaceBetween: 40,
            },
          }"
          loop
          autoplay
          centeredSlides
          style="--pagination-bottom: 0px; padding-bottom: 40px"
        >
          <swiper-slide v-for="(n, i) of $lang('barcelona.slider')" :key="i">
            <ImageCaption :fileName="n.img">
              <p class="h6">{{ n.caption }}</p>
            </ImageCaption>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <SectionHeader fileName="campus-mdr" id="madrid">
      <h1 v-html="$lang('madrid.title')" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-5">
            <h3 class="text-secondary mb-4" v-html="$lang('madrid.header')" />
            <h6 class="fw-300 lh-3 mb-5">
              {{ $lang("madrid.subheader") }}
            </h6>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <p>
              {{ $lang("madrid.paragraph1") }}
            </p>

            <p>
              {{ $lang("madrid.paragraph2") }}
            </p>

            <p class="mb-0">
              {{ $lang("madrid.paragraph3") }}
            </p>
          </div>
        </div>

        <div class="row mt-8">
          <div class="col-lg-12">
            <Accreditations theme="secondary" headingLevel="h4" />
          </div>
        </div>

        <div class="row mt-8">
          <div class="col-lg-3">
            <h3 class="mb-4 text-secondary">
              {{ $lang("madrid.information.title") }}
            </h3>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <div class="card card-outline-secondary d-flex flex-column h-100">
              <div class="card-header">
                <h6>{{ $lang("madrid.information.card1.title") }}</h6>
              </div>
              <div class="card-body mt-auto">
                <p class="mb-0">
                  {{ $lang("madrid.information.card1.body") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <div class="card card-outline-secondary d-flex flex-column h-100">
              <div class="card-header">
                <h6>{{ $lang("madrid.information.card2.title") }}</h6>
              </div>
              <div class="card-body mt-auto">
                <p class="mb-0">
                  {{ $lang("madrid.information.card2.body") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <div class="card card-outline-secondary d-flex flex-column h-100">
              <div class="card-header">
                <h6>{{ $lang("madrid.information.card3.title") }}</h6>
              </div>
              <div class="card-body mt-auto">
                <p class="mb-0">
                  {{ $lang("madrid.information.card3.body") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-9 pb-5">
        <swiper
          :modules="modules"
          :pagination="{ clickable: false }"
          :breakpoints="{
            '0': {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            '768': {
              slidesPerView: 2.5,
              spaceBetween: 40,
            },
          }"
          loop
          autoplay
          centeredSlides
          style="--pagination-bottom: 0px; padding-bottom: 40px"
        >
          <swiper-slide v-for="(n, i) of $lang('madrid.slider')" :key="i">
            <ImageCaption :fileName="n.img">
              <p class="h6">{{ n.caption }}</p>
            </ImageCaption>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="py-7 bg-secondary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("links.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('links.card1.title')"
              :to="{ name: 'meet_our_team' }"
            >
              <small class="m-0">
                {{ $lang("links.card1.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('links.card2.title')"
              :to="{ path: '/courses/our-courses' }"
            >
              <small class="m-0">
                {{ $lang("links.card2.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('links.card3.title')"
              :to="{ name: 'extra_activities' }"
            >
              <small class="m-0">
                {{ $lang("links.card3.body") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection
      fileName="footer-6"
      newSource="/web-v2/webp/footer-6.webp"
      theme="tertiary"
    >
      {{ $lang("contact_form.location") }}
    </ContactFormSection>

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import ImageCaption from "@/components/ImageCaption.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import CardButton from "@/components/CardButton.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";
import Accreditations from "@/components/Accreditations.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    CardButton,
    SectionHeader,
    ImageCaption,
    Swiper,
    SwiperSlide,
    Accreditations,
  },
};
</script>

<style></style>
